.sf-container {
    height: 60vh;
}

.sf-container > div > .blue-headline {
    padding-top: 30px;
    padding-bottom: 20px;
}

.sf-container .sf-body {
    height: calc(100% - 122px);
    overflow-y: auto;
    box-shadow: inset -2px 2px 20px 0 rgba(0,0,0,.08);
    padding: 0 16px;
}

.sf-container > div > .btns-wrapper {
    position: absolute;
    bottom: 0;
    width: calc(100% - 16px);
    padding: 8px;
    background: #fff;
    box-shadow: -2px 2px 20px 0 rgba(0,0,0,.06);
}

.sf-container .btns-wrapper > button:first-child {
    margin-left: auto;
    margin-right: 8px;
}

@media only screen and (max-width: 750px) {
    .sf-container {
        width: 100%;
        height: 100vh !important;
        border-radius: 0;
    }

    .sf-container .sf-body {
        height: calc(100% - 204px) !important;
    }
}