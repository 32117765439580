.actionable-input-container {
    margin: 16px 0;
}

.actionable-input.input-error-2 {
    box-shadow: 0 0 0 2px var(--colorR4);
}

.actionable-input > input {
    border: 1px solid var(--colorLight5);
    border-radius: 2px;
    padding: 5px 12px;
    width: calc(100% - 64px);
    line-height: 24px;
    outline: none;
}

.actionable-input:not(.input-error-2):focus-within, .actionable-input:not(.input-error-2) > input.input-used {
    box-shadow: 0 0 0 2px var(--colorB7);
}

.actionable-input-btn {
    width: 38px;
    height: 37px;
    box-shadow: inset 0 -1px 0 0 var(--colorLight5), inset 0 1px 0 0 var(--colorLight5), inset -1px 0 0 0 var(--colorLight5);
    background: var(--colorLight10);
    display: flex;
    cursor: pointer;
}

.actionable-input-btn > img {
    width: 14px;
    margin: auto;
}

input.input-error-2 {
    border: 1px solid var(--colorR2) !important;
}