/* VideoUploader.css */

.whatsapp-support {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99999999;
}

.btn-standard.whatsapp-support {
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0, 0.15);
    border-radius: 50px;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.btn-standard.whatsapp-support > div {
    margin-top: 12px;
    margin-right: 20px;
}

.whatsapp-support > img {
    height: 48px;
}

.video-uploader {
    width: 300px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
}
  
.drag-over {
    border-color: #007bff;
}
  
.uploaded-file {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.upload-box {
    text-align: center;
    cursor: pointer;
}
  
.upload-box p {
    margin: 0;
    padding: 10px;
}

input[type="file"] {
    display: none;
}
  
.video-show {
    width: 300px;
    height: 300px;
}

.video-uploader-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 3rem;
}

.flex-row {
    display: flex;
    column-gap: 10px;
}
.progress-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.progress-style{
    width: 80%;
    margin-left: 1rem;
    height: 2rem;
}
.progress-value{
    padding-left: 1rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
/* Define the spinner animation */
.spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
/*uploading Completed*/
.upload-complete{
    margin-bottom: 0px !important;
}
