
.mgmt-main.scans-dashboard {
    width: calc(100% - 100px);
}

.overview-section {
    margin-bottom: 40px;
}

.upc-inspections-box {
    overflow-x: hidden;
    max-height: 400px;
    max-width: 1000px;
}

.checkouts-today td {
    max-width: 35vw;
}

.checkouts-today tr > td:first-child {
    padding-left: 0;
}

.checkouts-today tr > td:nth-child(2) {
    max-width: 100px;
    overflow: hidden;
}

.checkouts-today tr > td:nth-child(3) {
    min-width: 66px;
}

.checkouts-today .para-label {
    max-width: 100px;
    white-space: pre;
}

.mgmt-main.scans-dashboard .mgmt-header {
    margin-top: 0 !important;
}


@media only screen and (max-width: 750px) {
    .checkouts-today tr > td:not(:first-child) {
        padding-left: 8px;
    }

    .overview-section > .shadow-box-1.pad-20-all {
        padding: 12px;
    }
    
    .checkouts-today .text-1-2, .checkouts-today .text-1 {
        font-size: 12px;
    }
    
    .checkouts-today .text-2, .checkouts-today .para-label {
        font-size: 11px;
    }
}

@media only screen and (max-width: 450px) {
    .upc-inspections-box {
        overflow-x: auto !important;
    }

    .checkouts-today .text-1-2, .checkouts-today .text-1 {
        font-size: 11px;
    }
    
    .checkouts-today .text-2, .checkouts-today .para-label {
        font-size: 10px;
    }

    .checkouts-today .para-label {
        padding: 0.5px 3px;
    }

    .checkouts-today tr>td:nth-child(1) {
        max-width: 120px;
    }
    
    .checkouts-today tr>td:nth-child(2) {
        max-width: 75px;
    }
    
    .checkouts-today tr > td:nth-child(3) {
        min-width: 55px;
    }
}
