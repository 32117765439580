.radio-group-container {
    margin: 16px 0;
}

.radio-container-1:not(.radio-disabled), .radio-container-1:not(.radio-disabled) > * {
    cursor: pointer;
}

.radio-container-1 {
    margin: 8px 0;
}

.radio-container-1 > input {
    margin-right: 8px;
    margin-top: 0;
    transform: translateY(2px);
}

.radio-container-1 > input:disabled ~ span {
    color: var(--colorDark4);
}