.page-content {
    overflow-y: hidden;
    height: 100vh;
}

.para-logo {
    position: absolute;
    top: 33px;
    left: 40px;
}

#para-logo {
    width: 153.8px;
    height: 42.5px;
    object-fit: contain;
}

.page-content > .user_sign_page {
    display: flex;
    height: 100%;
    margin: 0 40px;
}

.access-form {
    background: var(--colorLight1);
    width: 505px;
    margin: auto;
    backdrop-filter: blur(30px);
    border: none;
    -webkit-backdrop-filter: blur(30px);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
    -moz-box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
    box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
}

.access-form > form {
    margin: 20px 100px;
}

.user-sign-header {
    display: flex;
    margin-top: 45px;
}

.access-form > form .user-sign-header > h3 {
    flex-grow: 1;
    font-size: 36px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--colorDark1);
}

.user-sign-header > .switch-user-sign {
    margin-top: auto;
    margin-bottom: 30px;
}

.switch-user-sign > span {
    font-size: 16px;
    line-height: 1.18;
    color: #707070;
}

.switch-user-sign > span > a {
    color: #36a7cc !important;
}

.sign-in-options {
    border: solid #a7a7a7;
    border-width: 1px 0 0 0;
    padding: 0 20px;
    display: flex;
}

.sign-in-options  > .sio {
    padding: 4px 8px;
    margin-top: -1px;
    flex-grow: 1;
    text-align: center;
}

.sign-in-options .sio.sio-selected {
    border: solid #478ad8;
    border-width: 2px 0 0 0;
    cursor: default;
}

.sign-in-options .sio:not(.sio-selected) {
    margin-top: 1px;
    cursor: pointer;
}

.sign-in-options > .sio > span {
    font-size: 14px;
    line-height: 1.16;
    color: #a7a7a7;
}

.sign-in-options > .sio.sio-selected > span {
    color: #478ad8;
}

.simple-input-container > .input-icon {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.login-form .simple-input-container > .input-icon, .signup-form .simple-input-container > .input-icon {
    top: 23px;
}

.forgot-pass-btn > div {
    width: fit-content;
    margin-left: auto;
    margin-top: 6px;
    color: var(--colorDark3);
    letter-spacing: 0.15px;
    cursor: pointer;
}

.sign-form-err {
    height: 18px;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    color: var(--colorR3);
}

.login-container.simple-login-container .primary-btn.sign-form-btn {
    margin-bottom: 0;
}

.login-container:not(.login-container-active) {
    display: none;
}

.forgot-pass-modal {
    padding: 30px 30px 38px;
    margin-bottom: 45px;
}

.forgot-pass-modal > .back-container {
    margin-bottom: 20px;
}

.forgot-pass-modal .cp-main {
    margin-top: 0 !important;
}

@media screen and (max-width: 550px) {
    .access-form {
        background: transparent !important;
        width: 100%;
        box-shadow: none;
    }

    .access-form > form {
        margin: 20px 0;
    }

    .access-form > form .user-sign-header > h3 {
        font-size: 30px;
    }

    .switch-user-sign > span {
        font-size: 12px;
    }

    .simple-input-container > input {
        font-size: 12px !important;
        height: 40px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
    }

    .si-input-title {
        font-size: 12px;
        top: 13px;
    }

    .login-form .simple-input-container > .input-icon {
        top: 13px;
        font-size: 14px;
    }
}
