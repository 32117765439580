.display-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 70%;
    margin: 5% auto; */
    position: relative;
    height: 100vh;
    width: 100vw;
}

.rec-timestamp {
    position: fixed;
    top: 10px;
    left: 0;
}

.rec-timestamp > .ts-wrapper {
    width: fit-content;
    margin-left: -9px;
    background: var(--colorDark1);
    border-radius: 8px;
}

.rec-timestamp > .ts-wrapper > div {
    padding: 5px 8px 5px 17px;
    color: var(--colorLight1);
}

.rec-identifier {
    position: fixed;
    display: flex;
    top: 10px;
}

.rec-identifier > div {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background: #f5222d;
    animation: blink 2s infinite;
    -webkit-animation: blink 2s infinite;
    -moz-animation: blink 2s infinite;
    -o-animation: blink 2s infinite;
}

.rec-identifier > span {
    font-family: Roboto, sans-serif;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

@keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-khtml-keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-moz-keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-ms-keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-o-keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-webkit-keyframes blink {
    from { opacity: 0; }
    to { opacity: 1; }
}


video.video-camera-display {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    object-fit: cover;
}

#videoApp .btn-container {
    position: fixed;
    left: calc(50vw - 30px);
    bottom: 50px;
}

.btn-outer {
    width: 60px;
    height: 60px;
    background: none;
    border: 4px solid #ffffff;
    position: relative;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-sizing: content-box;
}

.btn-inner {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background: #f5222d;
    position: absolute;
    left: 4px;
    top: 4px;
}

.btn-container.btn-active .btn-inner {
    height: 30px;
    width: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    left: 15px;
    top: 15px;
}
