.popup-container {
    position: fixed;
    top: 20vh;
    left: 0;
    right: 0;
    max-height: 60vh;
    margin: 0 auto;
    z-index: 101;
    background-color: #fff;
    text-align: left;
    border-radius: 2px;
}

.popup-container:not(.non-padded-popup) {
    padding: 30px;
    max-width: 500px;
}

.popup-container.non-padded-popup {
    padding: 0;
    max-width: 560px;
}

.popup-container.non-padded-popup > .close-btn {
    margin-top: 30px;
    margin-right: 30px;
}

.popup-container.non-padded-popup > .back-container {
    margin-top: 30px;
    margin-left: 30px;
}

.popup-container.custom-scrollbar, .popup-container.custom-scrollbar-1 {
    overflow-y: auto;
}

.popup-container:not(.custom-scrollbar) {
    overflow: hidden;
}

@media only screen and (min-width: 750.01px) {
    .popup-container > .back-container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .popup-container {
        top: 0;
        left: 0;
        right: 0;
        width: unset;
        max-width: none;
        max-height: none !important;
        margin: 0 !important;
        border-radius: 0;
    }

    .popup-container:not(.non-padded-popup) {
        height: calc(100vh - 60px);
        padding: 30px 16px;
    }

    .popup-container.non-padded-popup {
        height: 100vh;
    }

    .popup-container > .close-btn {
        display: none;
    }

    .popup-container > .back-container {
        margin-bottom: 20px;
    }
}
