.connect-pms {
    width: 200px;
    height: 38px;
    margin: 40px auto 88px;
}

.pms-conn-item > .pms-conn-key {
    margin-right: 8px;
}

.pms-conn-details {
    flex-wrap: wrap;
    margin-top: 16px;
}

.pms-conn-client {
    border: 1px solid #dddfe252;
    padding: 8px 8px 16px;
    width: 260px;
    box-shadow: 0 1px 10px 2px rgb(0 0 0 / 8%);
    margin: 0 20px 20px 0;
}

.pms-conn-client > .h3 {
    margin-bottom: 8px;
    text-transform: capitalize;
}

.pms-conn-val.text-1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.pms-section {
    margin: 32px 0 12px;
}

@media only screen and (max-width: 810px) {
    .connect-pms {
        margin: 60px auto;
    }
}
