.page-content {
    /* overflow-y: hidden; */
    height: 100vh;
}
.logo-img-container {
    margin: 24px auto 0;
    position: relative;
    cursor: pointer;
}
.logo-img-container {
  margin: 35px auto 0;
  border-radius: 50px;
  border: solid 1px #ebebeb;
  background-color: #f6f6f6;
  width: 106px;
  height: 106px;
}
.logo-img-container > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
}
.page-content > .user_sign_page {
    display: flex;
    height: 100%;
    margin: 0 40px;
}
.top-bannerX {
    position: fixed;
    display: flex;
    z-index: -1;
    max-width: 100vw;
    overflow: hidden;
}
.top-bannerX > img {
    height: 100vh;
    background: #ece4de;
    filter: blur(10px);
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100vw;
}
.access-formX {
    background: var(--colorLight1);
    width: 505px;
    margin: auto;
    backdrop-filter: blur(30px);
    border: none;
    -webkit-backdrop-filter: blur(30px);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
    -moz-box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
    box-shadow: 0 0 40px 0 rgba(41, 102, 199, 0.17);
    margin-top: 8rem;
}

.access-formX > .form {
    margin: 20px 100px;
}

.user-sign-header {
    display: flex;
    margin-top: 45px;
}

.access-formX > .form .user-sign-header > h3 {
    flex-grow: 1;
    font-size: 36px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--colorDark1);
    text-align: center;
}

.checkout-result-container {
    padding-bottom: 20px;
}

@media only screen and (max-width: 550px) {
    .top-bannerX {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .access-formX {
        background: transparent !important;
        width: 100%;
        box-shadow: none;
    }

    .access-formX > .form {
        margin: 20px 0;
    }

    .access-formX > .form .user-sign-header > h3 {
        font-size: 30px;
    }
}