.ar-container-2 {
    width: 28vw;
    height: 60vh;
    z-index: 99999;
    overflow-y: scroll;
}

.ar-container-2 > .loader-container {
    top: 65px;
}

.ar-container-2 > div:last-child {
    height: 100%;
}

.info-container {
    padding: 1rem;
    border-radius: 8px;
    width: 90%;
}

.info-label {
    margin-bottom: 1rem;
    font-size: 16px; 
    font-weight: 700;
    
}

.info-value {
    font-size: 14px;
    font-weight: 400;
    padding-left: 5px;
}

.heading{
    color: var(--colorB2);
}

.ar-container-2 > div > .blue-headline {
    padding-top: 30px;
}

.ar-container-2 > div > .blue-headline{
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 750.01px) {
    .ar-container-2 > .back-container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .ar-container-2 {
        width: 90%;
        height: 100%;
        border-radius: 0;
    }

    .ar-item {
        margin-bottom: 4px
    }
}
