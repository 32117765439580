.ar-container {
    height: 60vh;
}

.ar-container > .loader-container {
    top: 65px;
}

.ar-container > div:last-child {
    height: 100%;
}

.ar-container > div > .blue-headline {
    padding-top: 30px;
}

.ar-container > div > .blue-headline, .ar-address {
    padding-left: 30px;
    padding-right: 30px;
}

.ar-address {
    margin-bottom: 15px;
}

.ar-body {
    height: 100%;
    overflow-y: auto;
    box-shadow: inset -2px 2px 20px 0 rgba(0, 0, 0, .08);
}

.ar-body > div > a > div {
    padding-left: 30px;
}

.ar-body > div:hover {
    background: var(--colorDark7);
}

.ar-link, .ar-link:hover, .ar-link:focus, .ar-link:active {
    text-decoration: none;
    color: inherit;
}

.ar-item-header {
    padding: 12px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ar-item-date > .ls-status {
    margin-left: 16px;
}

.ar-item-breakdown {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.ar-item {
    padding: 4px 8px;
    border-radius: 7px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-right: 8px;
}

.ar-item > span:first-child {
    margin-right: 4px;
}

.ar-item > span:last-child {
    color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 750.01px) {
    .ar-container > .back-container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .ar-container {
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }

    .ar-item {
        margin-bottom: 4px
    }
}