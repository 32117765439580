#confirm-popup > .btn-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}

.popup-container .btn-container > button:nth-child(2) {
    margin-left: 8px;
}

@media only screen and (max-width: 482px) {
    #confirm-popup {
        width: calc(100% - 50px);
        height: unset;
    }
}
