.sc-lobby {
    height: 100vh;
    width: 100vw;
    padding: 0;
}

.sc-lobby.incl-cl > .lobby-content {
    padding-top: 104px;
}

.sc-lobby:not(.incl-cl) > .lobby-content {
    padding-top: 257px;
}

.img-wrapper {
    width: 100vw;
    display: flex;
}

.img-wrapper > img {
    margin: auto;
}

.client-logo {
    margin-bottom: 50px;
}

.client-logo > img {
    height: 130px;
}

.app-logo > img {
    width: 228px;
}

.sc-lobby .btn-primary {
    margin-top: 50px;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    margin-left: calc(50vw - 100px);
}

.powered-by-wrapper {
    position: fixed;
    bottom: 30px;
    /* margin-left: 50vw; */
}

.powered-by-wrapper > div {
    position: relative;
    width: 100vw;
}

.help-btn {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    color: var(--colorDark3);
    font-weight: 500;
    letter-spacing: 0.15px;
    cursor: pointer;
}

.powered-by {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 25px;
    display: flex;
}

.powered-by > span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.22;
}

.powered-by > img {
    width: 63px;
    margin-left: 6px;
    margin-top: -1px;
}
