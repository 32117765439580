.debug-layer {
    z-index: 9999999999;
}

.sockets-view {
    position: fixed;
    top: 100px;
    left: 0;
    background: rgba(255,255,255,0.8);
    padding: 10px 20px;
    border-radius: 0 20px 20px 0;
    max-height: 250px;
    max-width: 50vw;
    overflow: auto;
    z-index: 9999999999;
}

.sockets-view > div > div.flexRow > div:first-child {
    margin-right: 6px;
}

.sockets-view > div > div.flexRow > div:nth-child(2) {
    font-weight: 700;
    color: var(--colorG1);
}

.sockets-view > div > div.flexRow {
    padding: 2px 0;
}

.sockets-view > div.h3 {
    margin-bottom: 6px;
}