
.in-roi-results {
    margin-top: 16px;
    margin-bottom: 16px;
}

.in-roi-results > div {
    width: calc(100%-24px);
    padding: 12px;
    align-self: stretch;
    margin-bottom: 8px;
    border-radius: 6px;
    background: rgba(241, 241, 253, 0.45);
}

.in-roi-results > div > .flexRow {
    margin-bottom: 8px;
}

.in-roi-results > div > .flexRow > div {
    margin-left: 6px;
    padding: 1px 0;
}