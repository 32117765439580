.pms-body-container {
    min-height: 200px;
}

.pms-body-container .dd-container-2 {
    position: relative;
}

.pms-body-container .dd-items-2 {
    width: 100%;
    max-width: inherit;
}

.pms-step.pms-step1 {
    margin-top: 35px;
    min-height: 130px;
}

.pms-step.pms-step2 > .btn-primary {
    margin-top: 32px;
    float: right;
}
