.dd-container-2.dd-full-width {
    position: relative;
}

.dd-container-2.dd-full-width .dd-items-2 {
    width: 100%;
}

.dd-selector-2 {
    justify-content: space-between;
    padding: 8px 15px 8px 16px;
    border: 1px solid var(--colorLight5);
    border-radius: 4px;
    cursor: pointer;
}

.dd-selector-2 > span, .dd-items-2 > .dd-item-2 {
    text-transform: capitalize;
}

.dd-selector-2:not(.dd-item-selected) > span {
    color: var(--colorDark6);
}

.dd-selector-2.dd-item-selected > span {
    color: var(--colorDark3);
}

.dd-selector-2 > img {
    height: 11px;
    margin: auto 0;
}

.dd-items-2 {
    position: absolute;
    margin-top: 6px;
    border-radius: 4px;
    background: var(--colorLight1);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 
                0 6px 16px 0 rgb(0 0 0 / 8%), 
                0 9px 28px 8px rgb(0 0 0 / 5%);
    z-index: 1;
    overflow: hidden;
}

.dd-items-2.overflow-y-auto {
    overflow-y: auto !important;
}

.dd-items-2 > .dd-item-2 {
    padding: 10px 16px;
    cursor: pointer;
}

.dd-items-2.dd-items-bordered > .dd-item-2:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .06);
}

.dd-item-2.dd-item-disabled {
    color: rgba(0,0,0,.25) !important;
    cursor: default !important;
    user-select: none !important;
}

.dd-items-2 > .dd-item-2:not(.dd-item-disabled):hover {
    background: var(--colorLight3);
}

.dd-items-2 > .dd-item-2.dd-item-selected {
    color: var(--colorB1);
}