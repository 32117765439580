.btn-standard {
    padding: 5px 16px;
    font-family: Roboto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 2px;
}

.btn-standard:not(.btn-disabled):hover {
    cursor: pointer;
}
  
.btn-primary {
    color: var(--colorLight1);
    background: var(--colorB1);
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 4.3%);
}
  
.btn-primary:not(.btn-disabled):hover {
    background: var(--colorB2);
    border: 1px solid var(--colorB2);
}
  
.btn-standard.btn-disabled {
    color: var(--colorDark4);
    background: var(--colorLight3);
    border: 1px solid var(--colorLight5);
    cursor: default;
}
  
.btn-primary:not(.btn-disabled):active {
    background: var(--colorB3);
    border: 1px solid var(--colorB3);
}
  
.btn-secondary {
    color: var(--colorDark3);
    background-color: var(--colorLight1);
    border: 1px solid var(--colorLight5);
}
  
.btn-secondary:not(.btn-disabled):hover, .btn-secondary:not(.btn-disabled).btn-selected {
    color: var(--colorB2);
    border-color: var(--colorB2);
}
  
.btn-secondary:not(.btn-disabled):active {
    color: var(--colorB3);
    border-color: var(--colorB3);
}

.buttons-wrapper.wrap-flexEnd {
    justify-content: flex-end;
}



/* Not used */
.btn-sign {
    width: 100%;
    border: none;
    margin-top: 24px;
    margin-bottom: 30px;
    padding: 16px 0;
    font-size: 24px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 0 rgb(41 102 199 / 47%);
    -moz-box-shadow: 0 0 10px 0 rgba(41, 102, 199, 0.47);
    box-shadow: 0 0 10px 0 rgb(41 102 199 / 47%);
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    color: #ffffff !important;
    background: #4c85db !important;
    background-image: -moz-linear-gradient(top, #4c85db 0%, #31adcb 100%) !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4c85db), color-stop(100%,#31adcb)) !important;
    background-image: -webkit-linear-gradient(top, #4c85db 0%,#31adcb 100%) !important;
    background-image: -o-linear-gradient(top, #4c85db 0%,#31adcb 100%) !important;
    background-image: -ms-linear-gradient(top, #4c85db 0%,#31adcb 100%) !important;
    background-image: linear-gradient(to bottom, #4c85db 0%,#31adcb 100%) !important;
}

.btn-landing {
    background-color: #ff5722;
    color: #fff;
    border: none;
}

.btn-text {
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.85);
}