section.create-company {
    max-width: 500px;
    margin: 100px auto auto;
    background: var(--colorLight1);
    padding: 50px 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    border-radius: 20px;
}

section.create-company .btn-sign {
    margin-top: 50px;
}

@media screen and (max-width: 750px) {
    section.create-company {
        width: calc(100vw - 60px);
        box-shadow: unset;
        margin-top: 70px;
    }

    section.create-company .btn-sign {
        font-size: 20px;
    }
}