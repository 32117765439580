.ic-cover {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
}

.target-frame-container {
    display: flex;
}

.tf-wrapper {
    /* max-width: 80px; */
    max-height: 150px;
    margin: 50px 20px 0 auto;
    box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.2);
    -o-box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.2);
    transition: ease-out 2s box-shadow, ease-out 2s max-height;
}

.tf-wrapper.found-frame:not(.zoom-frame) {
    /* max-width: 100px !important; */
    max-height: 187.5px !important;
}

.tf-wrapper.found-frame {
    box-shadow: -5px 5px 10px 2px rgb(0 0 0 / 20%), 
    0 0 0 2px var(--colorG1);
    transition: ease-in 0.2s box-shadow, ease-in 0.2s max-height;
}

.target-frame-container img {
    /* width: 80px; */
    height: 150px;
}

.target-frame-container > .found-frame:not(.zoom-frame) > img {
    /* width: 100px !important; */
    height: 187.5px !important;
}

.tf-wrapper.zoom-frame {
    max-height: calc(100vh - 100px) !important;
    transition: ease-in 0.2s box-shadow, ease-in 0.2s max-height;
}

.target-frame-container > .zoom-frame > img {
    height: calc(100vh - 100px) !important;
}

.instruction-wrapper {
    position: fixed;
    bottom: 50px;
    width: calc(100vw - 100px);
    left: 50px;
}

.instruction-label {
    display: flex;
    border-radius: 8px;
    background: #000;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.57;
    font-weight: bold;
    color: #ffffff;
}

.instruction-label .label-val {
    padding: 8px 25px;
    margin: 0 auto;
}

.instruction-label .label-counter {
    height: fit-content;
    padding: 8px 0;
    margin-left: auto;
    margin-right: 60px;
}

.instruction-compass {
    position: absolute;
    right: -20px;
    top: -14px;
}

.instruction-compass .c-dir {
    transform: rotate(var(--ang));
}

.scan-progress-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    width: 100vw;
    background: #e0e0d0;
}

.scan-progress-slider {
    height: inherit;
    border-radius: 0 4px 4px 0;
    background: var(--colorB3);
}

.top-layer-msg {
    position: fixed;
    top: 20px;
    left: -5px;
    transition: 1.2s;
    z-index: 100;
}

.top-layer-msg > .tlm-container {
    max-width: calc(100vw - 200px);
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(64,169,255,0.24);
    background: #fff;
    font-size: 14px;
    font-weight: 500;
}

.top-layer-msg.hide-msg {
    left: calc(-100vw + 200px) !important;
    transition: 1.2s;
}
