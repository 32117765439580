.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader-container > span {
  margin-top: 175px;
  padding: 0 50px;
  text-align: center;
  font-family: Rubik, 'Roboto';
}

.loader-container div {
    position: absolute;
    width: 90px;
    height: 51px;
}

.loader-container div:nth-of-type(2) {
      transform: rotate(60deg);
}

.loader-container div:nth-of-type(3) {
    transform: rotate(-60deg);
}

.loader-container > div div {
    width: 100%;
    height: 100%;
    position: relative;
}

.loader-container > div div span {
    position: absolute;
    width: 4px;
    height: 0%;
    background: var(--colorB6);
    z-index: 999999;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(80,130,195,1) 0%, rgba(54,167,204,1) 50%);
}

.loader-container .l-h1 {
    left: 0;
    animation: load1 7.2s ease infinite;
}

.loader-container .l-h2 {
    right: 0;
    animation: load2 7.2s ease .6s infinite;
}

.loader-container .l-h3 { 
    right: 0;
    animation: load3 7.2s ease 1.2s infinite;
}

.loader-container .l-h4 {
    right: 0;
    animation: load4 7.2s ease 1.8s infinite;
}

.loader-container .l-h5 {
    left: 0;
    animation: load5 7.2s ease 2.4s infinite;
}

.loader-container .l-h6 {
    left: 0;
    animation: load6 7.2s ease 3s infinite;
}

@keyframes load1  {
  0% { bottom: 0; height: 0}
  6.944444444% { bottom: 0; height: 100%}
  50% { top: 0; height: 100%}
  59.944444433% { top: 0; height: 0}
}

@keyframes load2  {
  0% { top: 0; height: 0}
  6.944444444% { top: 0; height: 100%}
  50% { bottom: 0; height: 100%}
  59.944444433% { bottom: 0; height: 0}
}

@keyframes load3  {
  0% { top: 0; height: 0}
  6.944444444% { top: 0; height: 100%}
  50% { bottom: 0; height: 100%}
  59.94444443% { bottom: 0; height: 0}
}

@keyframes load4  {
  0% { top: 0; height: 0}
  6.944444444% { top: 0; height: 100%}
  50% { bottom: 0; height: 100%}
  59.94444443% { bottom: 0; height: 0}
}

@keyframes load5  {
  0% { bottom: 0; height: 0}
  6.944444444% { bottom: 0; height: 100%}
  50% { top: 0; height: 100%}
  59.94444443% { top: 0; height: 0}
}

@keyframes load6  {
  0% { bottom: 0; height: 0}
  6.944444444% { bottom: 0; height: 100%}
  50% { top: 0; height: 100%}
  59.94444443% { top: 0; height: 0}
}