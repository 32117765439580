.side-notif-container {
    position: fixed;
    right: 50px;
    bottom: 50px;
    background: var(--colorLight1);
    max-width: 500px !important;
    padding: 9px 12px 9px 18px;
    border-radius: 10px;
    z-index: 99999;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
}

.side-notif-container > .close-btn {
    float: unset;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.side-notif-container > .close-btn > img {
    height: 13px !important;
    width: unset !important;
}

.side-notif-container > .side-notif-body {
    margin-right: 43px;
}

.side-notif-container > .side-notif-body > img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.side-notif-container > .side-notif-body > .notif-error {
    color: var(--colorR2);
}

.side-notif-container > .side-notif-body > .notif-success {
    color: var(--colorG1);
}

@media only screen and (max-width: 750px) {
    .side-notif-container  {
        right: 16px;
        bottom: 16px;
        max-width: calc(100vw - 62px) !important;
    }
}