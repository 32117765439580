.new-unit-container .btn-primary {
    width: 20%;
    margin-left: auto;
    margin-top: 14px;
}

.new-unit-container .actionable-input {
    width: 55%;
    min-width: 250px;
}

@media only screen and (min-width: 750.01px) {
    .new-unit-container > .back-container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .new-unit-container {
        height: 100%;
        left: 0;
        top: 0;
        padding: 30px 16px;
        max-width:none;
        border-radius: 0%;
    }

    .new-unit-container > .close-btn {
        display: none;
    }

    .new-unit-container > .back-container {
        margin-bottom: 30px;
    }

    .new-unit-container .actionable-input {
        width: unset;
    }

    .new-unit-container .btn-primary {
        width: 40%;
    }
}