.wrapper-text {
    margin-top: 1rem;
}

.addmore {
    margin-top: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
}

.email-box {
    padding: 0 0 10px;
    justify-content: space-between;
    display: flex;
}

.email-action-btns {
    display: flex;
    column-gap: 10px;
}

.email-action-btns > button {
    border: 0;
    padding: 5px 13px;
    border-radius: 0.2rem;
    margin: 0;
    background: var(--colorLight4);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.auto-email-config {
    padding: 10px 0 0;
}

.auto-email-config .dd-selector-2 > img {
    margin-left: 4px;
}

.auto-email-when .simple-input-container {
    margin-top: 0;
    margin-right: 8px;
}

.auto-email-when .simple-input-container > .simple-input-wrapper > input {
    padding: 6.5px 12px;
    max-width: 75px;
}

.dd-minw-140 {
    min-width: 140px;
}

.auto-email-when > .flexRow {
    margin-top: 10px;
}

.center-wrapper {
    display: flex;
    align-items: center;
}

.var-dropdown {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.copy-icon-container {
    margin-left: 10px;
    margin-top: 43px;
}

.mbt-20 {
    margin-top: 20px;
    margin-bottom: 20px 
}

.email-body {
    margin-top: 10px;
}

.ql-snow.ql-toolbar {
    display: block;
    background: var(--colorB7);
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.email-preview-frame {
    width: 100%;
    overflow: clip !important;
    overflow-clip-margin: content-box !important;
    border: 2px solid #1890ff;
    border-style: inset;
    max-height: 600px;
    min-height: 500px;
}

.email-preview-frame > div {
    margin: 8px;
    height: 584px;
    overflow-y: scroll;
}

.preview-email-title {
    margin-top: 20px;
    margin-bottom: 10px;
}
