.back-container {
    display: flex;
    cursor: pointer;
}

.back-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
    border: solid 1px #d9d9d9;
    margin-right: 9px;
}

.back-icon > div {
    border-left: 1px solid rgba(0, 0, 0, 0.85);
    border-top: 1px solid rgba(0, 0, 0, 0.85);
    z-index: 22;
    transform: rotate(-45deg);
    width: 9.4px;
    height: 9.4px;
    margin-top: 7px;
    margin-left: 9px;
}

.back-icon + div {
    line-height: 28px;
}
