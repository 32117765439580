.comment-page {
    margin-left: 72px;
    display: flex;
    flex-direction: column;
    /* max-height: 100%; */
}

.item-header {
    display: flex;
    flex-direction: column;
    margin-top: 19px;
}

.item-header > div {
    display: flex;
    font-size: 14px;
    line-height: 22px;
}

.unit-subject {
    margin: 8px 0;
}

.subject-text-box > .editable-input-box input {
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    color: #1890ff;
    font-size: 18px;
}

.editable-input-box > .read-box > input {
    border: none
}

.checked-resolved {
    background-color: #eaeaea;
    font-size: 14px;
    margin-left: 10px;
    padding: 1px 3px;
    line-height: 1.6;
}

.checked-resolved > img {
    border-radius: 20px;
    height: 11px;
    margin: 0 3px;
}

.comments-title {
    color: #1890ff;
    font-size: 18px;
    margin-top: 30px;
}

.comments-container {
    margin-right: 0 !important;
    width: calc(100% - 5px) !important;
}

.item-description {
    margin-top: 30px;
    display: flex;
}

.item-description > img {
    width: 122px;
    height: 126px;
    max-width: 122px;
    max-height: 126px;
}

.item-description > .editable-input-box > .edit-box, .item-description > .editable-input-box > .read-box {
    max-width: 300px;
}

.comments-container.pos-relative {
    height: 400px;
}

.comments-container.pos-relative > .loader-container {
    top: 150px;
}

.comment-box {
    width: 100%;
    display: inline-flex;
    margin-bottom: 27px;
}

.comment-box > img {
    width: 48px;
    border-radius: 22px;
}

.comment-body {
    width: calc(100% - 43px);
}

#comment-background {
    background-color: #e8f4ff;
}

.comment-body > textarea:not(.user-name) {
    width: 100%;
    padding: 0;
    font-size: 14px;
    font-family: Roboto;
    border: none;
    outline: none;
    background: none;
    resize: none;
}

.comment-body > textarea:not(.user-name):not([readonly]) {
    border: 1px solid rgba(0,0,0,0.15);
    padding: 2px;
    width: calc(100% - 10px);
}

.user-name {
    font-weight: bold;
    font-size: 12px;
}

.user-name > span {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 2px;
}

.comment-actions {
    margin-left: auto;
}

.comment-actions > img {
    margin-right: 5px;
    cursor: pointer;
}

.empty-wrapper {
    height: 300px;
}

.comment-page > textarea {
    width: calc(100% - 12px);
    border-color: #d9d9d9;
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 24px;
    resize: none;
    padding: 5px 0 0 10px;
    min-height: 45px;
}

.comment-page > textarea:focus {
    outline: none;
}

.comment-page > textarea::placeholder {
    font-family: Roboto;
}

.comment-page > button {
    width: 150px;
    margin-bottom: 5px;
}
.item-description > div {
    width: 100%;
    margin-left: 5px;
}
.item-description > div > textarea {
    border: none;
    resize: none;
    width: calc(100% - 15px);
    font-size: 14px;
    font-family: Roboto;
    color: rgba(0,0,0,.85);
    max-height: 150px;
    margin-right: 0;
}

.item-description > div > textarea:focus {
    outline: none;
}

.item-description > div > textarea:read-write {
    border: 1px solid #d9d9d9;
}

.item-description > div > svg {
    position: absolute;
}

.item-description > div > svg:hover {
    cursor: pointer;
}

@media only screen and (min-width: 750.01px) {
    .comments-container {
        margin: 10px 0;
        overflow-y: auto;
        max-height: 200px;
        min-height: 150px;
    }
}

@media only screen and (max-width: 750px) {
    .comment-page {
        max-height: unset;
        margin-left: 16px;
        margin-right: 7%;
    }
    
    .item-header > div {
        flex-direction: column;
    }

    .item-header > div:last-child {
        margin-bottom: 21px;
    }

    .item-description > div > textarea[readonly] {
        max-height: unset;
    }

    .edit-box > input {
        margin-right: 5px;
    }

    .checked-resolved {
        margin-left: unset;
    }

    .comment-page > .back-container {
        display: none;
    }

    .comments-container {
        height: 64vh;
        min-height: 150px;
        max-height: 200px;
        overflow-y: auto;
    }

    .comment-box {
        width: 100%;
    }

    .comment-page > textarea {
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
    }

    .comment-box, .comment-page > .comments-container > textarea {
        margin-top: 15px;
    }
}