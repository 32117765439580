.create-employee-container {
    min-height: 400px;
}

.submit-employee {
    margin: 32px 0 0 auto;
    width: 176px;
}


@media only screen and (min-width: 750.01px) {
    .create-employee-container > .input-container {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 750px) {
    .create-employee-container > .blue-headline {
        margin-top: 20px;
    }
}