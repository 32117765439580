table.fake-presentation-table th, table.fake-presentation-table td {
    font-size: 8.5px !important;
    padding: 0 3px;
    line-height: unset !important;
    text-align: center;
}

table.fake-presentation-table th {
    border-top: 1px solid var(--colorLight5);
}

table.fake-presentation-table th:not(:nth-child(even)) {
    border: 1px solid var(--colorLight5);
}

table.fake-presentation-table td:not(:last-child) {
    border-right: 1px solid var(--colorLight5);
}

table.fake-presentation-table tr:last-child td {
    border-bottom: none !important;
}

.scrollable-presentation {
    width: 100%;
    overflow-x: auto;
}

.excel-uploader {
    width: 100%;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    margin: 20px 0;
    padding: 10px 0;
}

.bulk-upload-container button.btn-primary {
    padding-top: 8px;
    padding-bottom: 8px;
}