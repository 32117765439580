.mgmt-main {
    margin: 100px auto;
    padding: 0 50px;
    max-width: 1920px;
}

.mgmt-header {
    justify-content: space-between;
    margin-bottom: 19px;
}

.top-pg-container {
    float: right;
    font-size: 12px;
}

.top-pg-container > div {
    line-height: 28px;
}

.mgmt-table > table {
    width: 100%;
}

.mgmt-table thead > tr {
    background-color: var(--colorLight10);
    box-shadow: -18px 0 0 -17px rgba(0, 0, 0, 0.06);
}

thead > tr > th {
    color: var(--colorDark3);
    font-size: 14px;
    text-align: left;
}

th, td {
    border-bottom: 1px solid var(--colorLight5);
}

td {
    padding-left: 16px;
    text-align: left;
}

th > div {
    display: flex;
    justify-content: flex-start;
    height: 22px;
    line-height: 22px;
}

th:not(:first-child) > div {
    border-left: 1px solid var(--colorLight4);
}

th > div > span {
    margin-left: 16px;
}

.sort-icons {
    height: 22px;
    width: 11px;
    margin: auto 8px;
    padding-top: 2px;
}

.sort-icons > img:first-child {
    margin-bottom: -3px;
}

.th-ds {
    width: 15vw;
    min-width: 135px;
}

.th-act{
    width: 22vw;
}

.th-name{
    width: 15vw;
}

tr {
    font-size: 14px;
    color: var(--colorDark3);
    height: 56px;
    position: relative;
}

.ls-status {
    margin-right: 10px;
}

.miss-baseline-label {
    width: fit-content;
    font-size: 13px;
}

.mgmt-table .report-btns {
    display: flex;
    justify-content: flex-start;
}

.scans-submissions .report-btns > .simple-btn > img {
    height: 22px;
    max-width: unset;
}

.report-btns > .img-btn {
    flex-grow: unset;
}

.simple-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 5px 16px;
    border: 1px solid var(--colorLight1);
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.08);
    background: var(--colorLight1);
}

.report-btns a {
    color: var(--colorDark3);
}

.report-btns > .simple-btn {
    flex-grow: 1;
}

.simple-btn.ls-fail {
    border: solid 1px var(--colorR2);
}

.report-btns > .more-items > img {
    width: 30px;
    height: 30px;
}

.mgmt-main .dd-items-2 {
    width: max-content;
    right: 0;
}

.mgmt-add-2{
    padding: 0.7rem 1rem;
}

.simple-btn:hover,
.more-items:hover,
th:nth-child(-n + 3):hover,
.mgmt-add-2:hover,
.radio-container {
    cursor: pointer;
}

.unit-labels.flexRow {
    flex-wrap: wrap;
}

.unit-labels > .para-label {
    margin-bottom: 8px;
}

.arrow-right {
    border-top: 2px solid var(--colorB2);
    border-right: 2px solid var(--colorB2);
    height: 6px;
    width: 6px;
    transform: rotate(45deg);
    margin-top: 4px;
    margin-right: 8px;
}

.more-btn{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.ar-container > div > .blue-headline {
    padding-top: 30px;
}

.ar-container > div > .blue-headline, .ar-address {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 750.01px) {
    .sort-active {
        background: var(--colorLight3);
    }

    td[data-th="Check Out"] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 25vw;
    }
}

@media only screen and (max-width: 1380px) {
    .mgmt-table tr {
        font-size: 13px;
    }
   
}

@media only screen and (max-width: 1300px) {
    .th-ls {
        width: 22vw;
    }
}

@media only screen and (max-width: 1200px) {
    .mgmt-main.scans-submissions {
        margin: 150px 35px;
    }

    .mgmt-table > table > tbody > tr > td {
        padding-left: 10px;
    }

    .ls-status,
    .generate-link.link-created > div > span {
        margin-right: 5px;
    }

    .generate-link.link-created > div > span {
        max-width: 125px;
    }

    .simple-btn {
        padding: 5px 10px;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1320px) {
    td[data-th="Rent"],
    td[data-th="Deposit"],
    td[data-th="Check Out"],
    td[data-th="Check In"] {
        white-space: nowrap;
    }

    td[data-th="Rent"] {
        width: 15%;
    }

    td[data-th="Deposit"] {
        width: 18%;
    }

    td[data-th="Check In"] {
        width: 16%;
    }

    td[data-th="Check Out"] {
        width: 16%;
    }
}

@media only screen and (max-width: 1100px) {
    .mgmt-main.scans-submissions {
        margin: 150px 0px;
    }

    .th-act{
        width: 25vw;
    }
}

@media only screen and (max-width: 1000px) {
    .ls-status {
        font-size: 11px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1100px) {
    th.th-act> div > span {
        display: none;
    }

    td:not([data-th]) {
        white-space: nowrap;
        width: 3%;
        min-width: 40px;
    }
}

@media only screen and (max-width: 750px) {
    .th-act {
        width: 100%;
    }
      
    .report-btns > .img-btn {
        flex-grow: 1;
    }

    .mgmt-main {
        margin: 80px auto;
        padding: 0 10px;
        min-width: 298px;
    }

    .mgmt-header {
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    .mgmt-header-btns {
        justify-content: space-between;
        width: 100%;
    }

    .mgmt-header-btns > button {
        width: 100%;
        margin: 16px 0;
    }

    .mgmt-sort {
        position: relative;
        width: 30%;
    }

    .more-items > .dd-item-2,
    .mgmt-sort .dd-item-2 {
        font-size: 13px !important;
        padding-right: 40px !important;
    }

    .mgmt-sort .dd-item-2 {
        display: flex;
        flex-direction: row;
    }

    .dd-item-2 > .sort-icons {
        margin: auto 8px auto 0;
    }

    .mgmt-table tr {
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 15px;
        margin: 10px 0;
        border: 1px solid var(--colorLight4);
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    }

    .mgmt-table td {
        margin: 15px 0;
        border: none;
        font-size: 14px;
    }

    .mgmt-table td[data-th]:before {
        content: attr(data-th);
        display: block;
        margin: 0 0 6px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
    }

    td[data-th="Rent"] > div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .ls-status {
        font-size: 12px;
        line-height: 20px;
        margin-left: 8px;
    }

    td[data-th="Rent"] > div > span:not(.ls-status) {
        align-self: center;
    }

    .generate-link.link-created > div > span {
        max-width: 182px;
    }

    .mgmt-table > table > tbody > tr > td:last-of-type {
        border-top: 1px solid var(--colorlight11);
        padding: 18px 8px 0;
        margin: 0 !important;
    }

    .mgmt-table .report-btns {
        justify-content: space-between;
    }

    .simple-btn {
        padding: 9px 8px;
        margin-right: 0px;
        border: solid 1px var(--colorLight11);
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    }

    .simple-btn:not(:first-child) {
        margin-left: 10px;
    }

    .report-btns > .more-items {
        position: absolute;
        right: 22px;
        top: 24px;
    }
}