.obi-one {
    height: 100vh;
    width: 100vw;
}

.obi-one > .img-wrapper {
    height: 229px;
    width: 100vw;
    overflow: hidden;
}

.obi-one > .img-wrapper > img {
    width: 100vw;
}

.obi-one > .vid-wrapper {
    display: flex;
    height: 460px;
    width: 100vw;
    overflow: hidden;
    background: var(--colorLight3);
    border-bottom: 1px solid var(--colorLight10);
}

.obi-one > .vid-wrapper > video {
    height: 100%;
    margin: 0 auto;
}

.obi-one > .step-content {
    margin-top: 40px;
}

.obi-one p {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    line-height: 1.22;
    color: rgba(0,0,0,0.85);
    margin: 0;
    padding: 0 43px;
    text-align: center;
}

.steps-wrapper {
    display: flex;
    margin: 47px 0;
}

.sw {
    display: flex;
    margin: 0 auto;
}

.step-rep {
    width: 16px;
    height: 3px;
    background: #1890FF;
    opacity: 0.3;
    border: none;
    border-radius: 1px;
    margin-right: 6px;
}

.step-rep:last-child {
    margin-right: 0;
}

.step-rep.active-step {
    width: 24px;
    opacity: 1;
}

.obi-one button {
    display: block;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
}

.obi-one .btn-text {
    margin-top: 15px;
}