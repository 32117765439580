.result-wrapper {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #0000006b;
}

.result-cover {
    z-index: 99999;
    width: calc(80vw - 40px);
    overflow-y: auto;
    -ms-overflow-y: auto;
    padding: 10px 20px;
    margin: auto;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    background: #ffffff;
    font-family: Roboto, sans-serif;
}

.result-wrapper:not(.d-none) > .result-cover {
    animation: appear_from_top 3s forwards;
    -webkit-animation: appear_from_top 3s forwards;
    -moz-animation: appear_from_top 3s forwards;
    -o-animation: appear_from_top 3s forwards;
}

.result-cover:not(.error-cover) {
    height: calc(70vh - 20px);
}

.result-cover.error-cover {
    height: fit-content;
    padding-bottom: 30px;
}

@keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}
@-khtml-keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}
@-moz-keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}
@-ms-keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}
@-o-keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}
@-webkit-keyframes appear_from_top {
    0%{margin-top:-90vh}
    100%{}
}

.result-cover > h2 {
    text-align: center;
    font-size: 24px;
}

.resi-header > h4 {
    font-size: 18px;
    margin-bottom: 8px;
}

.result-cover.error-cover > p.err-msg {
    font-size: 16px;
    line-height: 1.5;
    font-style: oblique;
    text-align: center;
}

.resi-content > ul {
    padding: 0 0 0 15px;
    margin: 0;
    list-style-type: square;
}

.inv-sep {
    width: 6px;
    height: 2px;
    background: #000;
    margin: 8px 5px;
}

.resi-content li > .inv-container {
    display: flex;
    position: relative;
}

.inv-label {
    font-size: 16px;
}

.action-buttons {
    margin: 50px auto;
    width: fit-content;
}

.action-buttons > :first-child {
    margin-right: 15px;
}

a.main-button {
    text-decoration: none;
    background-image: linear-gradient(to bottom, #4c85db 0%, #31adcb 100%);
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
}

.secondary-button {
    background: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 0 10px 1px #46464622;
}

.resi-content > ul > li {
    padding: 4px 0;
}