.change-password-container > .steps-container {
    display: none;
}

.cp-main > .pos-relative {
    margin-top: 120px;
}

.cp-step-item > .cp-text {
    margin: 32px 0;
}

.cp-step-item.cp-step-1 > .text-0 {
    text-align: justify;
    margin-top: 25px;
    margin-bottom: 10px;
}

.cp-step-item.cp-step-1 > .text-0 > u {
    font-weight: 500;
    color: var(--colorB4);
}

.step-2-label {
    margin-top: 8px;
}

.step-2-label > a {
    text-decoration: underline;
    color: #488ad8;
}

.cp-step-item.cp-step-5, .cp-step-item.cp-step-4 {
    margin: 42px auto;
}

.cp-step-item.cp-step-5 > img, .cp-step-item.cp-step-4 > img {
    width: 45px;
    display: flex;
    margin: 0 auto;
}

.cp-step-item.cp-step-4 > div, .cp-step-item.cp-step-5 > div {
    text-align: center;
    margin: 16px 0;
}

.cp-step-item.cp-step-5 > div {
    color: var(--colorR2);
}

.cp-step-item > button {
    margin: 36px 0 0;
    float: right;
}

@media only screen and (max-width: 750px) {
    .cp-main {
        margin-top: 120px;
    }
}
