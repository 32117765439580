.feedback-block {
    padding: 80px 40px 0;
}

.feedback-question {
    text-align: center;
}

.feedback-block > .rating-btns {
    width: 100%;
    margin-top: 20px;
}

.feedback-block > .rating-btns > * {
    flex-grow: 1;
    margin: 8px;
    height: 38px;
}

.rating-btns-labels > div {
    color: var(--colorDark6);
}

.rating-btns-labels > div:first-child {
    margin-right: auto;
}