.cl-container {
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
    background-color: #fff;
    width: 35vw;
    height: 80vh;
    position: fixed;
    bottom: 3vh;
    right: 4vw;
    padding: 29px 26px;
    overflow-y: auto;
    z-index: 999;
}

.cl-container > img {
    height: 19.1px;
    position: fixed;
}

.cl-body {
    display: flex;
    flex-direction: column;
}

.cl-body > .cl-item:nth-child(4) {
    margin-top: 22px;
}

.cl-items-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 20px 0;
}

.cl-item { 
    display: inline-flex;
    justify-content: space-between;
}

.cl-item.active {
    background-color: rgba(196, 196, 196, 0.1);
}

.cl-checkbox {
    width: 15px;
    height: 15px;
    flex-grow: 0;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    background-color: #fff;
    margin-right: 8px;
}

.chkbx-container {
    display: block;
    position: relative;
    padding-left: 22px;
    padding-right: 30px;
    cursor: pointer;
}

.chkbx-container > textarea, .chkbx-container > textarea:focus {
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    font-family: Roboto;
    background-color: transparent;
    padding-top:15px;
}

.chkbx-container > textarea:read-write {
    border: 1px solid #d9d9d9;
}

.chkbx-container > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 15px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.chkbx-container:hover input ~ .checkmark {
    background-color: rgba(196, 196, 196, 0.1);
}

.chkbx-container input:checked ~ .checkmark {
    background-color: #2196F3;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chkbx-container input:checked ~ .checkmark:after {
    display: block;
}

.chkbx-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cl-icons {
    display: inline-flex;
    margin: auto;
}

.cl-icons > img {
      margin-right: 8px;
      height: 14px;
}

.cl-icons > img.new-comment {
    filter: invert(58%) sepia(74%) saturate(2584%) hue-rotate(329deg) brightness(100%) contrast(103%);
}

.icon-counter {
    display: flex;
    height: 18px;
    width: 18px;
    margin-top: -2px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #1890ff, #2078db);
}

.icon-counter > span {
    height: fit-content;
    margin: auto;
    font-family: Roboto;
    font-size: 13px;
    color: #fff;
}

.cl-buttons {
    /* float: right; */
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, .08);
}

.cl-buttons > button {
      margin: 26px 16px 13px 0;
}

.cl-icons > img:hover, .checklist-btn > div:first-child:hover, .dots:hover, .ov-tab:hover, .bd-item > div:first-child:hover, .back-icon, .cl-container > img:hover {
    cursor: pointer;
}

@media only screen and (min-width: 750.01px) {
    .cl-container {
        max-width: 648px;
        max-height: 80vh;
        bottom: 0;
    }

    .cl-container > .back-container {
        display: none;
    }

    .cl-body {
        padding: 0 35px;
        overflow-y: auto;
        height: calc(80vh - 48px);
    }

    .cl-item > label {
        width: 100%;
    }
}

@media only screen and (max-width: 1350px) {
    .cl-container {
        width: 40vw;
    }
}

@media only screen and (max-width: 1100px) {
    .cl-container {
        width: 50vw;
    }
}

@media only screen and (max-width: 850px) {
    .cl-container {
        width: 60vw;
    }
}
 
@media only screen and (max-width: 750px) {
    .cl-container {
        width: 100vw;
        height: calc(100vh - 52px);
        top: 0;
        left: 0;
        bottom: 0;
        box-shadow: none;
        max-height: unset;
        padding: 26px 0;
        z-index: 100;
        overflow-y: auto;
    }

    .cl-container > img {
        display: none;
    }

    .cl-container > .back-container, .cl-body > div:nth-child(-n+3) {
        margin-left: 16px;
    }

    .cl-body-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 38px;
    }

    .cl-body {
        overflow-y: scroll;
        height: 80vh;
        margin-top: 15px;
    }

    .cl-body > div {
        margin-bottom: 10px;
    }

    .cl-item {
        position: relative;
        flex-direction: column;
        padding-left: 19px;
    }

    .cl-item.active {
        padding-bottom: 18px;
    }

    .cl-icons > img {
        margin-right: 30px;
    }

    .icon-counter {
        position: absolute;
        top: 15px;
        right: 12px;
        margin-top: 0;
    }

    /* .cl-buttons {
        display: flex;
        margin: 0 16px;
        justify-content: flex-end;
        position: absolute;
        bottom: 2vh;
        right: 0;
        width: 100%;
    } */

    .cl-buttons > .btn {
        width: 130px;
        height: 32px;
    }
}
