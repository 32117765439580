.comp-settings-container {
  max-width: 900px;
  border-radius: 22px;
}

.comp-settings-container.pos-relative {
  height: 400px;
}

.comp-settings-container.pos-relative > .loader-container {
  top: 60px;
}

.settings-nav-panel {
  min-width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 32px;
  background-color: #fff;
  position: sticky;
  top: 64px;
  z-index: 30;
  padding-top: 1rem;
  overflow-x: auto;
  white-space: nowrap;
}

.settings-tab {
  padding: 8px 10px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 16px;
  width: fit-content;
}

.settings-tab.coming-soon {
  cursor: default !important;
}

.settings-tab.active {
  color: #1890ff;
  stroke: #1890ff;
}

.settings-tab:not(.active) svg {
  color: #fff;
  stroke: #000;
}

.icon-label {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 16px;
  flex-shrink: 0;
  flex-basis: 100px;
}

.settings-tab.coming-soon > .icon-label {
  opacity: 0.6;
}

.settings-tab.coming-soon > .coming-soon-label {
  font-size: 11px;
  font-weight: 500;
  color: var(--colorB1);
}

.settings-header > .blue-headline {
  margin-top: 67px;
}

.save-section {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.save-section.change-occurred {
  width: 100%;
  background: #ffffffa8;
  z-index: 9999;
}

.save-section.change-occurred:not(.floating-bar) {
  position: relative;
}

.save-section.change-occurred.floating-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, .07);
}

.save-profile {
  height: 40px;
  width: 40vw;
  max-width: 200px;
  min-width: fit-content;
  margin: 15px auto;
}

@media only screen and (min-width: 750.01px) {
  .comp-settings-container {
    width: calc(100% - 64px);
    margin: 150px auto 0;
    padding: 32px 32px 100px;
    box-shadow: 0 8px 30px 0 rgba(28, 82, 123, 0.16);
  }

  .settings-header > .blue-headline {
    font-size: 24px;
  }
}

@media only screen and (max-width: 810px) {
  .comp-settings-container {
    width: 100%;
    margin: 69px auto 0;
    padding: 0 0 100px;
  }

  .settings-nav-panel {
    background-color: #e2dddd;
    /* display: flex;
        overflow-x: auto;
        white-space: nowrap;
        align-items: center; */
  }

  .settings-nav-panel::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .settings-tab {
    text-align: center;
    border-bottom: 2px solid transparent;
    transition: border-color 0.2s;
  }

  .settings-tab.active {
    border-color: #1890ff;
  }

  .comp-settings-container > section:not(:first-of-type) {
    margin: 8px 16px;
  }
}
