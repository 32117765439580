.in-pg-container {
    float: right;
    font-size: 12px;
}
.in-pg-container > div {
    display: inline-flex;
    margin: 42px 0;
}

.in-controller {
    width: 14px;
    margin-right: 8px;
    padding: 5px 7px;
    border: 1px solid var(--colorLight5);
    text-align: center;
    background: var(--colorLight1);
    box-sizing: content-box;
}

.in-controller:hover {
    cursor: pointer;
}

.in-controller.inactive {
    color: var(--colorLight5);
    cursor: default;
}

.in-page-input {
    width: 24px;
    padding: 2px;
    margin-right: 8px;
    text-align: center;
}

.in-page-input::-webkit-outer-spin-button, .in-page-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.in-page-input[type=number] {
  -moz-appearance: textfield;
}

.n-Of-Pages {
    line-height: 28px;
}

@media only screen and (max-width: 1100px) {
    .in-pg-container {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 750px) {
    .in-pg-container {
        margin: 0 auto;
        width: 105px;
        display: block;
        float: none;
    }

    .in-pg-container > div {
        position: relative;
    }

    .n-Of-Pages {
        position: absolute;
        right: -20px;
    }
}