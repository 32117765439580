@import url("https://use.typekit.net/taf8led.css");
@import url(//db.onlinewebfonts.com/c/13a7a4a64f0a4b39a872e732c91d2375?family=FuturaPT-Book);
@font-face { 
    font-family: "FuturaPT-Book";
    src: url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.eot");
    src: url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.eot?#iefix") format("embedded-opentype"), 
        url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.woff2") format("woff2"), 
        url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.woff") format("woff"), 
        url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.ttf") format("truetype"), 
        url("//db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.svg#FuturaPT-Book") format("svg"); 
}

section.email-content {
    width: 430px;
    padding: 60px 85px;
    margin: auto;
    background: #f2f0f0;
    box-sizing: content-box;
}
.email-header {
    width: 100%;
    margin-bottom: 50px;
}
.email-header > img {
    width: 100px;
}
.email-content > .main-content > h3, .email-content > .main-content > h1  {
    font-family: 'FuturaPT-Book', lato, 'Lato';
    line-height: 1.45;
    letter-spacing: -0.14px;
    color: #436d9d;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}
.email-content > .main-content > h3 {
    font-size: 25px;
}
.email-content > .main-content > h1 {
    font-size: 35px;
}
.email-content > .main-content > p {
    font-size: 16px;
    line-height: 1.22;
    letter-spacing: -0.06px;
    font-family: lato, 'Lato', Helvetica;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 50px;
}
.email-content .primary-btn {
    display: block;
    width: fit-content;
    text-decoration: none;
    padding: 12px 32px;
    margin: 0 auto;
    outline: none;
    border: none;
    color: #fff !important;
    background: #4c85db;
    background-image: linear-gradient(to bottom, #4c85db 0%, #31adcb 100%);
    box-shadow: 0 0 23px 0 rgba(67, 109, 157, 0.56);
    border-radius: 52px;
    font-size: 16px;
    line-height: 1.19;
    font-family: lato, 'Lato', Helvetica;
    font-weight: 700;
    font-style: normal;
}
.email-content .vertical-divider {
    width: 100%;
    height: 1px;
    margin: 55px 0 30px;
    background: #acacac;
}
.email-footer {
    padding: 0 40px;
}
.email-footer > p {
    font-family: lato, 'Lato', Helvetica;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.26;
    letter-spacing: -0.06px;
    color: #535353;
    text-align: center;
    margin: 20px 0 40px;
}
.email-footer > p > a {
    color: #436d9d;
}
.email-content .contact-info > span {
    display: block;
    font-family: lato, 'Lato', Helvetica;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.22;
    letter-spacing: 0.06px;
    text-align: center;
    color: #535353;
}
.email-content .rd-pt-sn {
    width: fit-content;
    margin: 30px auto 0;
}
.email-content .rd-pt-sn > a {
    margin-right: 16.8px;
}
.email-content .rd-pt-sn > a > img {
    width: 16.2px;
}