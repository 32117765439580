.smtp-section {
    margin: 32px 0;
}

section.smtp-section > .h3 {
    margin-bottom: 16px;
}

.presentation-block {
    padding: 16px;
    border: 1px solid var(--colorLight8);
    background: var(--colorLight3);
}

.presentation-block.edit-mode {
    background: none;
    border-color: var(--colorB7);
    border-width: 2px;
}

.presentation-block > div:first-child {
    margin-top: 0;
}

.presentation-block:not(.edit-mode) > .simple-input-container {
    display: flex;
    margin-top: 8px;
}

.presentation-block:not(.edit-mode) > .simple-input-container > .simple-input-wrapper > input {
    margin-top: -3px;
    margin-left: 12px;
    padding: 0;
    border: none;
}

.presentation-block > .update-smtp-pass-section {
    margin: 20px 0;
}

.presentation-block:not(.edit-mode) > .update-smtp-pass-section {
    display: none;
}

.presentation-block > .btns-section {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.presentation-block:not(.edit-mode) > .btns-section > button:not(.edit-btn), .presentation-block.edit-mode > .btns-section > .edit-btn {
    display: none;
}

.presentation-block.edit-mode > .btns-section > .btn-primary {
    margin-left: 8px;
}

.marl {
    margin-left: 1rem;
}
