.editable-input-box {
    cursor: pointer;
}

.edit-box {
    display: flex;
}

.edit-box > input {
    outline: 1px solid var(--colorLight5);
    border: none;
}

.read-box {
    height: auto;
    max-width: 80vw;
    display: flex;
}

.read-box > svg {
    height: 15px;
    width: 15px;
    margin: 3px;
}
