.new-unit-container .btn-primary {
    width: 20%;
    margin-left: auto;
    margin-top: 14px;
}

.new-unit-container .actionable-input {
    width: 55%;
    min-width: 250px;
}

@media only screen and (min-width: 750.01px) {
    .new-unit-container > .back-container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .new-unit-container {
        height: 100%;
        left: 0;
        top: 0;
        padding: 30px 16px;
        max-width: none;
        border-radius: 0%;
    }

    .new-unit-container > .close-btn {
        display: none;
    }

    .new-unit-container > .back-container {
        margin-bottom: 30px;
    }

    .new-unit-container .actionable-input {
        width: unset;
    }

    .new-unit-container .btn-primary {
        width: 40%;
    }

    .fields-ten {
        flex-direction: column;
        align-items: center;
        margin-top: -.7rem;    
    }
    
    .space{
        margin-block: -0.7rem;
    }
    
    .fields-ten-2 {
        display: flex;
        flex-direction: column;
        align-items: center;   
    }
    
    .new-unit-container > .flexColumn {
        overflow-y: scroll;
        height: 100%;
        margin-top: -2.7rem;
    }    
}

.input-w {
    width: 13rem !important;
}

.fields-ten {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.f-end {
    display: flex;
    justify-content: center;
    align-items: center;
}