.settings-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
}

/*****FOR TEMP BACK BUTTON***/
.settings-header {
    position: relative;
}

.settings-header > .back-container {
    position: absolute;
    top: 0;
    left: 0;
}

.settings-header > .back-container:hover {
    cursor: pointer;
}

.settings-header > .blue-headline {
    margin-top: 47px;
}


@media only screen and (max-width: 750px) {
    .settings-header > .back-container {
        top: -20px;
    }
    .settings-header > .blue-headline {
        margin-top: 25px;
    }
}


/*END FOR TEMP BACK BUTTON*/
.banner-zone {
    opacity: 0.7;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: solid 1px #e0e0e0;
    background-color: #f6f6f6;
    position: relative;
    height: 244px;
    border-radius: 22px;
}

.banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 22px;
}

.banner-btn {
    width: 25px;
    height: 25px;
    padding: 17px;
    border-radius: 50px;
    border: solid 1px #ebebeb;
    background-color: #f4fafd;
    position: absolute;
    left: 32px;
    bottom: 22px;
    cursor: pointer;
    z-index: 10;
}

.banner-btn > div {
    width: 30px;
    height: 30px;
}

.banner-btn > div > img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.name-logo-container {
    width: 421px;
    height: 282px;
    padding: 24px 49px 18px;
    border-radius: 30px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 8px 30px 0 rgba(28, 82, 123, 0.16);
    background-color: #fff;
    margin: -100px auto 0;
    z-index: 0;
}

.logo-img-container {
    margin: 24px auto 0;
    position: relative;
    cursor: pointer;
}

.logo-img-container > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.logo-img-container > div {
    width: 50px;
    height: 50px;
}

.logo-img-container > div > img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.c-name-box {
    position: relative;
    width: fit-content;
    margin: 15px auto 0;
}

.c-name-box > .c-name-input {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #1890ff;
    border: none;
    width: fit-content;
}

.c-name-box > img {
    width: 10px;
    position: absolute;
    top: 6px;
    right: 10px;
}

.btn-upload {
    width: 180px;
    height: 37px;
    margin: 18px auto;
}

.inputs.company {
    margin-top: 62px;
}

.comp-settings-container > .inputs > .input-container {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.input-label {
    font-size: 14px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.save-section {
    display: flex;
    flex-direction: column;
}

.save-section.change-occurred {
    width: 100%;
    background: #ffffffa8;
    z-index: 9999;
}

.save-section.change-occurred:not(.floating-bar) {
    position: relative;
}

.save-section.change-occurred.floating-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, 0.07);
}


@media only screen and (min-width: 750.01px) {
    .settings-header > .blue-headline {
        font-size: 24px;
    }

    .settings-tab.company {
        margin-right: 32px;
    }

    .logo-img-container {
        margin: 24px auto 0;
        border-radius: 50px;
        box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #ebebeb;
        background-color: #f6f6f6;
        width: 106px;
        height: 106px;
    }

    .profile-img {
        width: 100%;
    }
}


@media only screen and (max-width: 810px) {
    .banner-zone {
        height: 150px;
    }

    .banner-btn > div, .banner-btn > div > img {
        width: 20px;
        height: 20px;
    }
    
    .banner-btn {
        width: 15px;
        height: 15px;
        padding: 15px;
        left: 12px;
        top: 8px;
        bottom: unset;
    }
    
    .name-logo-container {
        display: block;
        /* height: 175px; */
        width: calc(100% - 40px);
        margin-top: 26px;
        border: solid 1px #ebebeb;
        background: none;
        border-radius: 10px;
        margin: -60px auto 0;
        padding: 0;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
    }
    
    .logo-img-container {
        width: 75px;
        height: 75px;
        margin-top: 20px;
        border-radius: 50%;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    }
    
    .c-name-box > .c-name-input {
        width: calc(100% - 4px);
    }
    
    .btn-upload {
        display: block;
        height: 29px;
        width: 30vw;
        min-width: 118px;
        margin: 26px auto;
    }
    
    .logo-headline {
        font-size: 1.5rem;
        margin: 25px auto;
    }

    .input-container > div:first-of-type {
        margin-top: 0px;
    }
}
