
textarea.email-body {
    resize: block;
    width: calc(100% - 16px);
    border-radius: 10px;
    padding: 6px 8px;
}

textarea.email-body:focus {
    outline-color: var(--colorB3);
    outline-width: 1px;
}
