.card-body {
    width: calc((100vw - 300px) / 3);
    min-width: 350px;
    max-width: 438px;
    margin: 0 25px 25px 0;
    padding: 24px 19px 19px 16px;
    border-radius: 5%;
    background: var(--colorLight1);
}

.bd-data-container > img {
    width: 122px;
    height: 126px;
    margin: 0 23px 33px 0;
}

.buttons-wrapper {
    justify-content: flex-end;
}

.buttons-wrapper > button:first-child {
    margin-right: 14px;
}

@media only screen and (min-width: 750.01px) {
    .card-body {
        max-height: 238px;
    }
}
@media only screen and (max-width: 930px) {
    .card-body {
        min-width: 320px;
        margin-bottom: 15px;
    }

    .card-body:nth-child(odd) {
        margin-right:15px
    }

    .card-body:nth-child(even) {
        margin-right:0
    }
}

@media only screen and (max-width: 750px) {
    .card-body {
        margin: 0 0 19px 25px;
        min-width: 252px;
        width: 72vw;
    }

    .buttons-wrapper {
        justify-content: space-between;
        margin-top: 22px;
    }

    .bd-data-container > img {
        width: 75.8px;
        height: 77px;
        margin: 0 24px 17px 0;
    }
}