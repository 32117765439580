.simple-input-container {
    position: relative;
    margin-top: 20px;
    z-index: 0;
}

.simple-input-container.no-margin {
    margin: 0;
}

.simple-input-label {
    margin-bottom: 10px;
}

.simple-input-container > .simple-input-wrapper {
    position: relative;
}

.simple-input-container > .simple-input-wrapper > input {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    padding: 5px 12px;
    border: 1px solid var(--colorLight5);
    border-radius: 2px;
    outline: none;
    background: transparent;
    font-family: Roboto;
    color: var(--colorB4);
    box-sizing: content-box;
}

.simple-input-container > .simple-input-wrapper > input.input-2 {
    height: 46px;
    border-radius: 10px;
    padding: 10px 15px 0;
    border: 1px solid var(--colorLight6);
}

.simple-input-container > .simple-input-wrapper > input::placeholder {
    color: var(--colorLight7);
}

.simple-input-container > .simple-input-wrapper > input:focus, .simple-input-container > .simple-input-wrapper > input:not(:placeholder-shown) {
    border-color: var(--colorB5);
    border-width: 2px;
}

.simple-input-container > .simple-input-wrapper > input.input-err {
    border-color: var(--colorR2);
    border-width: 2px;
    box-shadow: 0 0 0 2px var(--colorR4);
}

.si-input-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 16px;
    color: var(--colorLight7);
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    z-index: -1;
}

.simple-input-container > .simple-input-wrapper > input:focus ~ .si-input-title, .simple-input-container > .simple-input-wrapper > input:not(:placeholder-shown) ~ .si-input-title {
    font-size: 11px;
    top: 6px;
    transform: unset;
    color: var(--colorB5);
}

.simple-input-container > .simple-input-wrapper > input.input-err ~ .si-input-title {
    color: var(--colorR3);
}

input[type=number].in-input-no-appearance::-webkit-inner-spin-button, 
input[type=number].in-input-no-appearance::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

@media screen and (max-width: 550px) {
    .simple-input-container > .simple-input-wrapper > input {
        font-size: 14px !important;
        height: 40px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
    }
}