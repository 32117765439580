.tt-body {
    position: absolute;
    /* top: -30px; */
    /* left: 62px; */
    top: -40px;
    left: -20px;
    padding: 4px 7px;
    border: solid 1px var(--colorLight5);
    border-radius: 22px;
    background: var(--colorLight1);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
}