.video-loader {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000043;
    z-index: 100;
}

.loading-wrapper {
    width: calc(70vw - 40px);
    height: fit-content;
    margin: auto;
    padding: 60px 20px;
    text-align: center;
    background: #fff;
    box-sizing: content-box;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

.img-wrapper-rel {
    display: flex;
    width: 100%;
}

.img-wrapper-rel > img {
    margin: auto;
}

.simple-loader-container {
    position: relative;
    display: flex;
    height: 90px;
    width: 90px;
    margin: auto auto 20px;
}

.simple-loader-text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin: auto;
    text-align: center;
}

.help-btn {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    color: var(--colorDark3);
    font-weight: 500;
    letter-spacing: 0.15px;
    cursor: pointer;
}

.simple-loader {
    position: absolute;
    height: 50px;
    width: 50px;
    margin: auto auto 0;
    border: 5px solid transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-top: 5px solid #4c85dbde;
    border-left: 5px solid #4c85db8c;
    border-bottom: 5px solid #4c85db4a;
    border-right: 5px solid #4c85db33;
    animation: spin 0.8s linear infinite;
    -moz-animation: spin 0.8s linear infinite;
    -webkit-animation: spin 0.8s linear infinite;
    -o-animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-khtml-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-moz-keyframes spin {
    0% { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); }
}
@-ms-keyframes spin {
    0% { -ms-transform: rotate(0deg); }
    100% { -ms-transform: rotate(360deg); }
}
@-o-keyframes spin {
    0% { -o-transform: rotate(0deg); }
    100% { -o-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

.vl-text {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    margin-top: 20px;
}

.loading-wrapper > .text-5 {
    color: var(--colorDark6);
    margin: 4px 0 20px;
}

.load-success-btn {
    margin-top: 30px;
}