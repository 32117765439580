.employees {
    margin: 32px 0;
}

.employees-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 24px;
}

.employees-header > div:first-child {
    font-weight: 700;
}

.employees-header > div:last-child {
    font-size: 14px;
    color: #1890ff;
    cursor: pointer;
}

.employees > table {
    width: 100%;
    table-layout: fixed;
}

.employees > table > tbody > tr > td {
    padding: 0px;
}

.employees > table > tbody td[data-th="Status"] {
    text-transform: capitalize;
}

.employees-table th, .employees-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    padding: 8px; 
}

.employees-table th:nth-child(3), .employees-table td:nth-child(3) {
    width: 30%; 
}

.employees-table tbody td:not([data-th]) {
    width: 30px;
}

.employees-table .dd-container-2 > .dd-items-2 {
    right: 0;
    margin-top: -4px;
}

.employees-table > tbody > tr > td:last-child img {
    cursor: pointer;
    height: 25px;
}