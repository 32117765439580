@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.report-body {
    margin-top: 67px;
    padding: 0 50px;
    text-align: left;
    font-size: 14px;
    font-family: Roboto;
    color: var(--colorDark3);
}

.image-presentation-popup {
    height: 95vh;
    width: fit-content;
    max-width: 80vw !important;
    max-height: 95vh !important;
    top: 2.5vh;
    border-radius: 25px;
    margin: 0 auto !important;
    box-shadow: 0px 0px 50px 20px rgb(0 0 0 / 24%);
    background: none;
}

.image-presentation-popup > .close-btn {
    display: flex;
    height: fit-content;
    float: unset !important;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 8px;
    margin-top: unset !important;
    margin-right: unset !important;
    z-index: 99;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 18px 1px rgba(0, 0, 0, 0.28);
}

.images-gallery {
    position: fixed;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
}

.images-gallery > img {
    cursor: pointer;
}

.image-presentation {
    height: 95vh;
    width: fit-content;
    max-height: inherit;
    max-width: inherit;
}

.image-presentation > img {
    height: 95vh;
    max-width: inherit;
    max-height: inherit;
}

.report-body > .back-container {
    width: fit-content;
    margin-top: 30px;
    color: var(--colorDark3);
}

.ov-header {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
}

.ov-header .h2:not(.blue-headline) {
    padding: 4px 0 4px 8px;
    margin-bottom: 10px;
    border-left: 3px solid var(--colorB4);
    color: var(--colorB4);
}

.ov-buttons {
    position: relative;
    align-self: baseline;
    margin: 20px 19px 0 0;
}

.ov-buttons > button {
    align-self: flex-end;
    margin-right: 35px;
}

.dots {
    margin-top: 5px;
    height: 19px;
}

/* .ov-buttons .dd-items-2 {
    right: 0;
    width: max-content;
} */

.past-scans-dd.dd-items-2 {
    left: 0;
}

.past-scans-dd.dd-items-2 > .dd-item-2 > div:last-child {
    margin: auto 0 auto auto;
}

.past-scans-dd.dd-items-2 > .dd-item-2 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.exp-report-container > .dd-items-2 {
    top: 30px;
    right: 0;
    /* padding-bottom: 60px; */
    max-height: 550px;
    overflow-y: auto;
}

.dd-item-2.bd-data-container > img {
    width: 61px;
    height: 63px;
    margin: auto 16px auto 0;
}

.dd-item-2 > input.mul-selection-checkbox {
    height: 18px;
    width: 18px;
    margin: auto 10px auto 0;
}

.dd-item-2.bd-data-container .buttons-wrapper > button {
    padding: 4px 10px;
    margin-right: 0;
}

.dd-item-2.bd-data-container .buttons-wrapper {
    margin-top: auto;
    justify-content: flex-end !important;
}

.dd-item-2.bd-data-container {
    width: 300px;
}

.dd-item-2.bd-data-container > .flexColumn {
    width: calc(100% - 105px);
}

.dd-explanation, .exp-pdf-note {
    padding: 0 16px;
    max-width: 300px;
}

.dd-explanation > p {
    line-height: 1.5;
    text-align: justify;
    letter-spacing: 0.35px;
}

.dd-buttons-panel {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    padding: 10px 20px;
    width: calc(100% - 40px);
    box-shadow: 0 0 10px 0 var(--colorDark4);
}

.dd-buttons-panel > button {
    width: 100%;
}

.eol-notice {
    padding: 8px 0;
    text-align: center;
}

.exp-pdf-note > textarea {
    width: calc(100% - 16px);
    padding: 4px 8px;
    resize: vertical;
    font-family: 'Roboto';
    border-radius: 8px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    border: 1px solid var(--colorDark4);
    margin-top: 4px;
}

.exp-pdf-note {
    padding: 4px 16px 20px;
}

.exp-pdf-item-text {
    width: 100%;
    padding: 4px 8px;
    resize: none;
    font-family: 'Roboto';
    border-radius: 8px;
    line-height: 1.5;
    letter-spacing: .2px;
    border: 1px solid var(--colorDark4);
    margin-top: 4px;
}

.exp-pdf-item-note {
    padding: 4px 0 6px;
}

.exp-report-container .dd-item-2 > input.mul-selection-checkbox:not(:checked) ~ .flexColumn textarea {
    background: rgba(0, 0, 0, 0.01);
    opacity: 0.7;
}


.ov-body {
    margin-top: 16px;
    display: flex;
}

.ov-body > video {
    width: 519px;
    height: 250px;
    border-radius: 10px;
    background-color: black; /* TODO - remove after add of url */
}

.ov-summary-container {
    margin-left: 55px;
}

.ov-summary-container.pos-relative > .loader-container {
    top: 150px;
}

.ov-summary-tabs {
    width: 360px;
}

.ov-tab {
    width: 50%;
    padding-bottom: 12px;
}

.ov-tab.active-tab {
    border-bottom: 2px solid var(--colorB1);
}

.divider {
    border-bottom: 1px solid var(--colorDark6);
}

.ov-data {
    display: none;
    margin-top: 25px;
    margin-bottom: 0;
}

.ov-data.active-data {
    display: block;
}

.summary-breakdown {
    margin-top: 30px;

}

.bd-item > div {
    border-bottom: 1px solid var(--colorLight4);
}

.bd-item > div:first-child {
    padding: 17px 0;
}

.bd-item > .bd-data > section.empty-state {
    position: relative !important;
    height: unset !important;
    padding-bottom: 15px;
}

.bd-data {
    height: 0;
    max-height: 0;
    margin: 0 -50px;
    padding: 0 51px;
    background: rgba(196, 196, 196, 0.2);
    opacity: 0;
    overflow: hidden;
    transition: max-height 3s ease-out, padding 0.3s ease-out, opacity 1s ease-out;
}

.bd-data.active-bd {
    height: auto;
    min-height: 100px;
    max-height: 99999px;
    padding-top: 15px;
    opacity: 1;
    transition: max-height 3s ease-in, padding 0.5s ease-in;
}

.bd-data > div {
    flex-wrap: wrap;
    justify-content: flex-start;
}

.checklist-btn {
    position: fixed;
    bottom: 3vh;
    right: 4vw;
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 var(--colorDark5);
    background: var(--colorLight1);
    width: 150px;
    height: 40px;
    vertical-align: middle;
    z-index: 90;
}

.checklist-btn > div {
    align-self: center;
    margin-left: 16px;
}

.checklist-btn > div:first-child > img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: 1px;
}

.checklist-btn > div:first-child > span {
    vertical-align: top;
    margin-right: 5px;
}



.view-t-card-body {
    position: relative;
    min-width: 280px;
    max-height: 150px;
    margin: 24px 25px 25px 0;
    border-radius: 5%;
    background: var(--colorLight1);
    box-shadow: 0 0 10px 0 rgb(41 102 199 / 27%);
    overflow: hidden;
    cursor: pointer;
}

.view-t-card-body img {
    max-height: 300px;
    max-width: 50%;
    transform: translateY(-25%);
}

.view-t-card-label {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0 0;
    z-index: 80;
    text-align: center;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.75), transparent);
}

.kitchenware_view {
    margin-top: 10px;
    margin-bottom: 25px;
}

.kitchenware_view > .flexRow {
    overflow-x: auto;
}

.summary-breakdown > .h3 {
    margin-bottom: 16px;
}

.image-presentation > .im-label {
    position: absolute;
    width: 50%;
    padding: 8px 0px;
    line-height: 25px;
    text-align: center;
    background: var(--colorB4);
    color: #fff;
}

.image-presentation > .image-presentation {
    display: inline-block;
}

.image-presentation.non-desktop > .im-label {
    width: 100% !important;
}

.item-categories-wrapper {
    padding: 0 0 5px;
    overflow-x: auto;
    flex-wrap: nowrap !important;
    scroll-behavior: smooth;
    margin-bottom: 20px;
}

.item-categories-wrapper > .item-category {
    min-width: 20px;
    margin-right: 8px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    flex: 0 0 auto;
}

.item-categories-wrapper > .item-category.selected-item-category {
    background: var(--colorB7) !important;
    border: 1px solid var(--colorB4) !important;
    color: var(--colorB4);
    font-weight: bold;
}

.item-category.selected-item-category > .remove-ic-selection {
    margin-left: 6px;
    margin-top: -1px;
}

.item-categories-wrapper > .item-category:not(.selected-item-category) > .remove-ic-selection {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .desktop-only {
        display: none !important;
    }
}

@media only screen and (min-width: 1100px) {
    .non-desktop {
        display: none !important;
    }
}


.image-presentation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image-presentation > div {
    position: relative;
    width: 50%;
}

.image-presentation .title-label {
    background: #2d527c;
    color: #fff;
    padding: 4px 0;
    text-align: center;
}

.image-presentation img {
    height: calc(95vh - 33px);
}

.images-gallery > div {
    margin: 20px 10px;
    background: #2d527c;
    text-align: center;
    padding: 4px 0;
    border-radius: 4px;
    color: #fff;
}

.images-gallery > .switch-img-view {
    display: none;
}

.change-pic-btn {
    position: absolute;
    margin-top: 10px;
    margin-left: 5px;
    padding: 4px 12px;
    border-radius: 6px;
}

.edit-pic-nav {
    position: absolute;
    display: flex;
    width: calc(100% - 24px);
    margin: 8px 16px 0 8px;
    justify-content: space-between;
}

.edit-pic-nav .back-icon {
    background: #fff;
}

.edit-pic-nav.edit-mod-2 > .back-container {
    visibility: hidden;
}

.edit-pic-nav .back-icon > div {
    height: 7.4px;
    width: 7.4px;
    margin-top: 8px;
    margin-left: 9px;
}

.inner-edit-btns-layer.flexRow > button:first-child {
    margin-right: 8px;
}

.imgs-selection-gallery {
    height: calc(95vh - 33px);
    width: calc((95vh - 33px)* 720 / 1280);
    flex-wrap: wrap;
    background: #fff;
}

.imgs-selection-gallery > .imgs-selection-gallery-item {
    height: fit-content;
    margin: 8px 0 0 8px;
    cursor: pointer;
}

.imgs-selection-gallery img {
    height: 140px;
}

@media only screen and (min-width: 750px) and (max-width: 1100px) {
    .image-presentation-popup, .image-presentation, .image-presentation > div {
        height: 80vh;
    }

    .image-presentation img {
        height: calc(80vh - 33px);
    }
}

@media only screen and (min-width: 500px) and (max-width: 750px) {
    .image-presentation-popup, .image-presentation, .image-presentation > div {
        height: fit-content;
    }

    .image-presentation img {
        height: unset;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .image-presentation > div:not(.active-img) {
        display: none !important;
    }

    .image-presentation-popup, .image-presentation, .image-presentation > div {
        width: calc(100vw - 30px);
        height: fit-content;
    }

    .image-presentation img {
        width: calc(100vw - 30px);
        height: auto;
    }

    .images-gallery > .switch-img-view {
        display: block;
        height: fit-content;
        padding: 6px 12px;
        margin: auto 10px;
    }
}



@media only screen and (max-width: 1100px) {
    .ov-body > video {
        width: 450px;
    }

    .ov-summary-container {
        margin-left: 35px;
    }
}

@media only screen and (max-width: 1100px) {
    .ov-data {
        padding-left: 19px;
    }
}

@media only screen and (max-width: 1000px) {
    .ov-body > video {
        width: 400px;
    }
}

@media only screen and (max-width: 930px) {
    .bd-data {
        padding: 15px 10px;
    }
}

@media only screen and (max-width: 850px) {
    .ov-summary-tabs {
        width: 260px;
    }
}

@media only screen and (min-width: 750px) {
    .images-gallery {
        top: 0;
        width: 100px;
        height: 100vh;
        overflow-y: auto;
    }

    .images-gallery > img {
        width: 80px;
        margin: 20px 10px;
    }
}

@media only screen and (max-width: 750px) {
    .report-body {
        padding: 0px 15px;
    }

    .image-presentation-popup {
        height: fit-content !important;
        width: 90vw !important;
        max-width: 90vw !important;
    }

    .image-presentation-popup>.back-container {
        display: none;
    }

    .images-gallery {
        bottom: 0;
        width: 100vw;
        height: 150px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
    }

    .images-gallery > img {
        height: 130px;
        margin: 10px 20px;
    }

    .image-presentation, .image-presentation > img {
        height: auto;
        width: 90vw;
    }

    .ov-header {
        flex-direction: column;
    }

    .ov-header > div:first-child > div {
        max-width: 200px;
        margin-bottom: 5px;
    }

    .ov-buttons {
        width: 100%;
        margin-top: 11px;
        margin-right: 0px;
    }

    .ov-buttons > button {
        width: 100%;
        margin: 0;
        height: 32px;
    }

    .exp-report-container > .dd-items-2 {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        top: 0;
        margin: 0;
        right: unset;
        max-height: unset;
        left: 0;
    }

    .dd-explanation, .exp-pdf-note {
        max-width: unset;
    }

    .dd-item-2.bd-data-container {
        width: unset;
    }

    .dd-buttons-panel {
        position: fixed;
    }

    .dots {
        top: -79px;
        right: 10px;
        position: absolute;
    }

    .ov-buttons > .dd-more {
        top: -40px;
    }

    .ov-body {
        flex-direction: column;
    }

    .ov-body > video {
        width: 100%;
        margin-bottom: 25px;
    }

    .ov-summary-container {
        margin: 0;
    }

    .ov-summary-container.pos-relative > .loader-container {
        margin-bottom: 150px;
    }

    .ov-empty-mobile {
        position: relative;
        min-height: 150px;
    }
    
    .ov-summary-tabs {
        width: 100%;
    }

    .ov-data {
        margin-top: 20px;
    }

    .summary-breakdown {
        margin-top: 0px;
        margin-bottom: 70px;
    }

    .bd-data {
        padding: 0;
        margin: 0 -15px;
    }

    .bd-data > div {
        flex-wrap: nowrap;
        padding-right: 15px;
    }

    .bd-data.active-bd > div {
        overflow-x: scroll;
    }
}
