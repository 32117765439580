.mgmt-main {
    margin: 100px auto;
    padding: 0 50px;
    max-width: 1920px;
}

.mgmt-header {
    justify-content: space-between;
    margin-bottom: 19px;
}

.mgmt-search {
    justify-content: space-between;
    height: 36px;
    width: 298px;
    padding: 4px 10px 7px;
    border: solid 1px var(--colorLight5);
    border-radius: 20px;
}

.mgmt-search > input {
    width: 100%;
    height: 24px;
    border: none;
    outline: none;
    background: none;
}

.mgmt-search > img {
    width: 16px;
    height: 16px;
    margin: auto 0;
}

.mgmt-header-btns > button:not(:last-child) {
    margin-right: 15px;
}

.mgmt-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    border-radius: 2px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    background-color: var(--colorB1);
    color: var(--colorLight1);
}

.top-pg-container {
    float: right;
    font-size: 12px;
}

.top-pg-container > div {
    line-height: 28px;
}

.mgmt-table > table {
    width: 100%;
}

.mgmt-table thead > tr {
    background-color: var(--colorLight10);
    box-shadow: -18px 0 0 -17px rgba(0, 0, 0, 0.06);
}

thead > tr > th {
    color: var(--colorDark3);
    font-size: 14px;
    text-align: left;
}

th, td {
    border-bottom: 1px solid var(--colorLight5);
}

td {
    padding-left: 16px;
    text-align: left;
}

th > div {
    display: flex;
    justify-content: flex-start;
    height: 22px;
    line-height: 22px;
}

th:not(:first-child) > div {
    border-left: 1px solid var(--colorLight4);
}

th > div > span {
    margin-left: 16px;
}

.sort-icons {
    height: 22px;
    width: 11px;
    margin: auto 8px;
    padding-top: 2px;
}

.sort-icons > img:first-child {
    margin-bottom: -3px;
}

.th-action {
    width: 20vw;
}


tr {
    font-size: 14px;
    color: var(--colorDark3);
    height: 56px;
    position: relative;
}

.ls-status {
    margin-right: 10px;
}

.miss-baseline-label {
    width: fit-content;
    font-size: 13px;
}

td[data-th="Last Report"] > .flexRow {
    flex-wrap: wrap;
    padding: 6px 0;
}

td[data-th="Last Report"] > .flexRow > div:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 6px;
}

.generate-link > a {
    color: var(--colorG2);
}

.generate-link.link-created > div {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.generate-link.link-created > div > a {
    max-width: 120px;
    margin-right: 10px;
    color: var(--colorDark2);
}

.generate-link > div img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.copy-options {
    position: relative;
    margin-right: 8px;
}

.copy-options > .dd-items-2 {
    margin-top: 40px;
    right: unset !important;
    left: 0 !important;
}

.copy-options > .simple-btn.flexRow {
    flex-direction: row !important;
}

.copy-options > .simple-btn > .copy-icon {
    margin-left: 4px;
}

.mgmt-table .report-btns {
    display: flex;
    justify-content: flex-start;
}

.simple-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 5px 16px;
    border: 1px solid var(--colorLight1);
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .08);
    background: var(--colorLight1);
}

.report-btns a {
    color: var(--colorDark3);
}

.report-btns > .simple-btn {
    flex-grow: 1;
}

.simple-btn.ls-fail {
    border: solid 1px var(--colorR2);
}

.report-btns > .more-items > img {
    width: 30px;
    height: 30px;
}

.mgmt-main .dd-items-2 {
    width: max-content;
    right: 0;
}

.simple-btn:hover, .more-items:hover, .copy-icon:hover, th:nth-child(-n+3):hover, .mgmt-add:hover, .radio-container {
    cursor: pointer;
}


.table-row-extension {
    background: var(--colorLight3);
}

.table-row-extension td {
    padding: 20px 16px 30px;
}

.loo-placeholder {
    min-height: 100px;
    padding: 0 0 80px;
}

.table-row-extension > td > .empty-state {
    height: 120px !important;
}

.unit-labels.flexRow {
    flex-wrap: wrap;
}

.unit-labels > .para-label {
    margin-bottom: 8px;
}

.table-row-extension .unit-info {
    margin-top: 20px;
}

.table-row-extension .unit-info-item {
    padding: 10px 0 0 8px;
}

.arrow-right {
    border-top: 2px solid var(--colorB2);
    border-right: 2px solid var(--colorB2);
    height: 6px;
    width: 6px;
    transform: rotate(45deg);
    margin-top: 4px;
    margin-right: 8px;
}


@media only screen and (min-width: 750.01px) {
    .sort-active {
        background: var(--colorLight3);
    }

    td[data-th=Unit] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 25vw;
    }
}

@media only screen and (max-width: 1380px) {
    .mgmt-table tr {
        font-size: 13px;
    }

    .generate-link.link-created > div > span {
        max-width: 150px;
    }
}

@media only screen and (max-width: 1300px) {
    .th-ls {
        width: 22vw;
    }
}

@media only screen and (max-width: 1200px) {
    .mgmt-main {
        margin: 150px 35px;
    }

    .mgmt-table > table > tbody > tr > td {
        padding-left: 10px;
    }

    .ls-status, .generate-link.link-created > div > span {
        margin-right: 5px;
    }

    .generate-link.link-created > div > span {
        max-width: 125px;
    }

    .simple-btn {
        padding: 5px 10px;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1320px) {
    td[data-th="Last Report"], td[data-th="Share"], td[data-th="Unit"], td[data-th="Reference"] {
        white-space: nowrap;
    }
    
    td[data-th="Last Report"] {
        width: 15%;
    }

    td[data-th="Share"] {
        width: 18%;
    }

    td[data-th="Reference"] {
        width: 16%;
    }
    
    td[data-th="Unit"] {
        width: 25%;
    }
}

@media only screen and (max-width: 1100px) {
    .mgmt-main {
        margin: 150px 0px;
    }

    .th-action {
        width: 10vw;
    }
}

@media only screen and (max-width: 1000px) {
    .ls-status {
        font-size: 11px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1100px) {
    th.th-action > div > span {
        display: none;
    }
    
    td:not([data-th]) {
        white-space: nowrap;
        width: 3%;
        min-width: 40px;
    }
}

@media only screen and (max-width: 750px) {
    .mgmt-main {
        margin: 80px auto;
        padding: 0 10px;
        min-width: 298px;
    }

    .mgmt-header {
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    .mgmt-search {
        width: 55%;
        max-width: 300px;
    }

    .mgmt-header-btns {
        justify-content: space-between;
        width: 100%;
    }

    .mgmt-header-btns > button {
        width: 100%;
        margin: 16px 0;
    }

    .mgmt-sort {
        position: relative;
        width: 30%;
    }

    .more-items > .dd-item-2, .mgmt-sort .dd-item-2 {
        font-size: 13px !important;
        padding-right: 40px !important;
    }

    .mgmt-sort .dd-item-2 {
        display: flex;
        flex-direction: row;
    }

    .dd-item-2 > .sort-icons {
        margin: auto 8px auto 0;
    }

    .mgmt-table tr {
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 15px;
        margin: 10px 0;
        border: 1px solid var(--colorLight4);
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    }

    .mgmt-table td {
        margin: 15px 0;
        border: none;
        font-size: 14px;
    }

    .mgmt-table td[data-th]:before {
		content: attr(data-th);
		display: block;
		margin: 0 0 6px;
        font-size: 12px;
		color: rgba(0, 0, 0, 0.45);
	}

    /* td[data-th="Last Report"] > div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    } */

    .ls-status {
        font-size: 12px;
        line-height: 20px;
        margin-left: 8px;
    }

    td[data-th="Last Report"] > div > span:not(.ls-status) {
        align-self: center;
    }

    .generate-link.link-created > div > span {
        max-width: 182px;
    }

    .mgmt-table > table > tbody > tr > td:last-of-type {
        border-top: 1px solid var(--colorlight11);
        padding: 18px 8px 0;
        margin: 15px 10px;
    }

    .mgmt-table .report-btns {
        justify-content: space-between;
    }

    .simple-btn {
        padding: 9px 8px;
        margin-right: 0px;
        border: solid 1px var(--colorLight11);
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    }

    .simple-btn:not(:first-child) {
        margin-left: 10px;
    }

    .report-btns > .more-items {
        position: absolute;
        right: 22px;
        top: 24px;
    }
}