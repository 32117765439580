.update-ref-subject > span {
    font-weight: bold;
}

.update-ref-input {
    margin-bottom: 20px;
}

.update-ref > button {
    float: right
}

@media only screen and (min-width: 750.01px) {
    .update-ref > .back-container {
        display: none;
    }

    .update-ref-header.blue-headline {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 750px) {
    .update-ref {
        top: 0;
        left: 0;
        height: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        border-radius: 0;
    }

    .update-ref > .close-btn {
        display: none;
    }
}
