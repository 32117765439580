.bsp-container {
    height: 80vh;
    width: fit-content;
}

.bsp-container > div {
    height: 100%;
    width: 100%;
}

.bsp-container .h2 {
    padding: 30px 55px 0 30px;
}

.bsp-container .text-3 {
    padding: 0 30px 20px;
}

.bsp-body {
    display: flex;
    background: #000;
    width: 100%;
}

.bsp-body > video {
    height: calc(80vh - 97px);
    margin: auto;
}

.popup-container.has-bsp-container {
    max-height: 80vh !important;
    top: 10vh !important;
    width: fit-content !important;
}


@media only screen and (max-width: 750px) {
    .popup-container.has-bsp-container {
        max-height: unset !important;
        top: 0 !important;
        width: 100vw !important;
        max-width: unset;
    }

    .bsp-container {
        width: 100%;
    }

    .bsp-container .h2 {
        padding-top: 0;
    }

    .bsp-body {
        background: unset;
    }

    .bsp-body > video {
        max-height: calc(100vh - 150px);
        height: 100%;
        max-width: 100vw;
    }
}