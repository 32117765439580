.base-empty-state {
    width: 200px;
    height: 64px;
    text-align: center;
}

.empty-state {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.non-abs-empty-state {
    position: relative;
    padding: 60px 100px 40px;
}

.base-empty-state > img {
    margin: auto;
}

.base-empty-state > img.lg {
    width: 128px;
    height: 64px;
}

.base-empty-state > img.md {
    width: 100px;
    height: 54px;
}

.base-empty-state > img.sm {
    width: 64px;
    height: 28px;
}

.base-empty-state > .text-1-2 {
    color: var(--colorDark6);
}