.settings-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
}

/*****FOR TEMP BACK BUTTON***/
.settings-header {
    position: relative;
}

.settings-header > .back-container {
    position: absolute;
    top: 0;
    left: 0;
}

.settings-header > .back-container:hover {
    cursor: pointer;
}

.change-password {
    margin-top: 62px;
}

@media only screen and (max-width: 750px) {
    .settings-header > .back-container {
        top: -20px;
    }
    
    .settings-header > .blue-headline {
        margin-top: 25px;
    }
}
